.flip-card {
    background-color: transparent;
    width: 299px;
    height: 417px;
    perspective: 1000px;
    margin-left: 10vw;
  }

 

  .flip-card-news {
    background-color: black;
    width: 299px;
    height: 417px;
    perspective: 1000px;
    margin-left: 10vw;
  }

  .flip-card-news:hover .flip-card-inner{
    transform: rotateY(180deg);
    height: 200%;
    z-index: 99;

  }

  .flip-card-news:hover ~ .flip-card-news{
    transform: translateY(400px)
    /*got it*/
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  
  .flip-card img{
    max-height: 417px;
  }
  

  .flip-card-front, .flip-card-back {
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: transparent;
    color: black;
  }
  
  .flip-card-back {
    background-color: transparent;
    color: white;
    transform: rotateY(180deg);
  }


  .hover-grow-vert {
    transform: scale(0, 2);

  }