.page-container {
    margin-top: 70px;
    height: 100vh;
    font-size: 18px;
    color: white;
    overflow: hidden;
}

/*
.partnersLogo{
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 100vh;
}

.partnersLogo .col{
    padding: 5px;
    margin: 5px;
}
*/

.partnersLogo img{
    border-style: solid;
    border-width: 1px;
    border-color:lightgrey;
}

.news-container{
    margin: 70px;
}

.accordion {
    background-color: rgb(248, 248, 248);
    color: rgb(223, 223, 223);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    text-align: center;
    color: rgb(232, 116, 20);
    transition: 0.4s;
  }

  .products-page {

  }


  .active, .accordion:hover {
    background-color: #ccc;
  }

  .panel {
    padding: 0 18px;
    background-color: white;
    padding-left: 45px;
    padding-right: 45px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  /*
    Rule affects all placeholder text on a stdpage
    Opacity needs to be set for Firefox browser
  */

  .form-control::placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control::-ms-input-placeholder { /* Microsoft Edge compat*/
    color: rgb(0, 0, 0);
  }




