@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');


@font-face {
  /*font-family: 'brandon-grotesque-light';
  src: url('../src/assets/Fonts/Brandon-grotesque-light/3845E0_0_0.woff2') format('woff2');
  */
  font-family: 'Heebo', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
}





body {
  margin: 0;
  padding: 0;
  font-family: Heebo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  /* overflow-y: hidden; */
  height: 100%;
}




.fullHeight{
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  max-height: 100vh;
}


@media (max-width:760px) { 
    /* matches 'btn-xs' */
    .btn-responsive-gb {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }
}


