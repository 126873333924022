@import url(https://fonts.googleapis.com/css2?family=Heebo&display=swap);
@font-face {
  /*font-family: 'brandon-grotesque-light';
  src: url('../src/assets/Fonts/Brandon-grotesque-light/3845E0_0_0.woff2') format('woff2');
  */
  font-family: 'Heebo', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
}





body {
  margin: 0;
  padding: 0;
  font-family: Heebo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  /* overflow-y: hidden; */
  height: 100%;
}




.fullHeight{
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  max-height: 100vh;
}


@media (max-width:760px) { 
    /* matches 'btn-xs' */
    .btn-responsive-gb {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }
}



.Header {
    position: fixed;
    top: 0; /* Stick it to the top */
    max-height: 70px;
    width: 100vw;
    z-index: 2;
    display: grid;
    grid-template-areas: "logo nav";
  
    /* Cosmetics */
    background-color:black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .Logo {
    grid-area: logo;
    height: 70px;
    margin-left: 17vw;
  }
  
  .Nav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(6, auto);
    align-items: center;
    justify-items: center;
    z-index: 3;
  }
  .Nav a {
    color: #fff;
    font-size: 26px;
    font-weight: 500;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;
  }
  .Nav a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .Nav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: 70ms;
    transition: 70ms;
  }
  
  .Nav button:active {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
  }

  #highlightLink {
    color: #e87414;
  }

  #burgerIcon {
    width: 7vw;
    padding: 3px;
  }
  
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }
  .Burger:active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  @media (max-width: 700px) {
    .Header {
      grid-template-areas: "logo burger" "nav nav";
      background-color: rgb(0,0,0, .75) !important;
    }

    .Logo {
      margin-left: 1vw;
    }
    .Nav {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 20px;
  
      padding: 80px 0 30px;
      /* background: rgba(40, 44, 47, 0.95); */
      /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .Nav > a:hover {
      background: rgba(40, 44, 47, 0.95);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .Nav > a{
      font-size: 35px;
      /* text-shadow: 2px 4px 3px rgba(0,0,0,0.3); */
    }
    .Burger {
      display: inline;
    }

    .gb-nav-item {
      align-content: flex-start;
      background: rgba(0, 0, 0, 0.95);
      height: 100vh;
    }




  }
  
  .NavAnimation-enter {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: opacity 350ms, -webkit-transform 350ms;
    transition: opacity 350ms, -webkit-transform 350ms;
    transition: opacity 350ms, transform 350ms;
    transition: opacity 350ms, transform 350ms, -webkit-transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transition: opacity 350ms, -webkit-transform 350ms;
    transition: opacity 350ms, -webkit-transform 350ms;
    transition: opacity 350ms, transform 350ms;
    transition: opacity 350ms, transform 350ms, -webkit-transform 350ms;
  }
  


.productscontainer {
    margin-top: 10vh;
}

.mainsplash {
    /*padding-top: 70px;*/
    position: relative;
    height: 100vh;
    /* margin-left: 6vw;
    margin-right: 6vw; */
    background-color: black;
    z-index: -2;
    /* position: relative; */
}

.gb-logo{
    /* padding-left: 0 !important; */
    -webkit-animation: fade-in-out-in 8s ease-in-out forwards;
            animation: fade-in-out-in 8s ease-in-out forwards;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
    min-width: inherit;
}

.splash-columns{
    width: 30vw;
    padding-left: 7vw;
}


.gb-nav{
    opacity: 0;
    -webkit-animation: fade-in 4s 2s forwards;
            animation: fade-in 4s 2s forwards;
}

.base-image {
    /* height: calc(100vh - 70px); */
    position: absolute;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    
}

.fade-image {
    /* height: calc(100vh - 70px); */
    position: absolute;
    width: 100%;
    -webkit-animation: fade-out 4s forwards;
            animation: fade-out 4s forwards;
    object-fit: cover; 
}

.fade-image:after {
    background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(0, 0, 0, 0.0)),
      color-stop(70%, rgba(0, 0, 0, 0.0)),
      color-stop(80%, rgba(0, 0, 0, 0.2)),
      color-stop(90%, rgba(0, 0, 0, 0.7)),
      color-stop(95%, rgba(0, 0, 0, 0.9)),
      to(rgba(0, 0, 0, 1.0))
    );
    background: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.0) 70%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0.7) 90%,
      rgba(0, 0, 0, 0.9) 95%,
      rgba(0, 0, 0, 1.0) 100%
    );
    background: linear-gradient(
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.0) 70%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0.7) 90%,
      rgba(0, 0, 0, 0.9) 95%,
      rgba(0, 0, 0, 1.0) 100%
    );
  }

.splash-grid {
    height: inherit;
    margin-left: 0;
    padding-left: 0;
}

.splash-grid .row {
    height: 33vh;
    width: 100vw;
    align-items: center;
    flex-wrap: nowrap;
}

.splash-grid .col {
    float: right;
}

#supplier-art {
    /* padding-left: 40vw; */
    min-height: 40vh;
    float: right;
    padding-right: 10vw;
}

.splash-grid img {
    height: 19vh;
    opacity: 0;
    padding: 20px;
    /* animation: fade-in 4s 6s forwards;     */
}

.first-fade{
    -webkit-animation: fade-in 6s forwards;
            animation: fade-in 6s forwards;
}

.second-fade{
    -webkit-animation: fade-in 7s forwards;
            animation: fade-in 7s forwards;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.third-fade{
    -webkit-animation: fade-in 8s forwards;
            animation: fade-in 8s forwards;
}

.fourth-fade-fade{
    -webkit-animation: fade-in 8s forwards;
            animation: fade-in 8s forwards;
}

.fifth-fade{
    -webkit-animation: fade-in 8s forwards;
            animation: fade-in 8s forwards;
    -webkit-animation-delay: 9s;
            animation-delay: 9s;
}

.logo-move{
    -webkit-animation: fade-in 6s forwards;
            animation: fade-in 6s forwards;
}

@-webkit-keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
}

@-webkit-keyframes fade-in{
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fade-in{
    from {opacity: 0;}
    to {opacity: 1;}
}

@-webkit-keyframes fade-in-out-in{
    20% { opacity: 1; }
    85% {opacity: 0;}
    100% {opacity: 1}
}

@keyframes fade-in-out-in{
    20% { opacity: 1; }
    85% {opacity: 0;}
    100% {opacity: 1}
}

@media (max-width: 800px){
    .hide-on-small{
        visibility: hidden;
    }
}


@media (max-width: 800px){
    .shrink-on-small{
        max-height: 175px;
    }
}

/* Revised homepage styles */
.leadbox {
    color: white;
    position: absolute;
    top: 18vh;
    left: 18vw;
    max-width: 30vw;
    /*border: 2px solid white;*/

}

.leadbox > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 20px;

}

.leftheader {
    font-size: 56px;
    font-weight: bolder;
    line-height: 65px;
}



.partnerbox > p {
    font-size: 16px;
    color: #817e7e;
}

.partnerimages {
    display: flex;
    flex-direction: row;
    width: 65%;
    margin-left: 9vw;
}

.marqueeimage {
    width: 100px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 2px;
    opacity: .6;
} 


.hashnavdummy {
    height: 70px;
}


.productsection {
    color: white;
    margin-bottom: 25vh;
}

.sectionbody {
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    margin-right: 10vw;
    justify-content: space-between;
}

.centerheader {
    font-size: 46px;
    line-height: 65px;
    text-align: center;
}

.productsection > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 15px;
    text-align: center;
}

.sectionbody > img {
    max-height: 500px;
}

.sectionbody > div > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 9vh;
    padding-left: 5vw;
    padding-right: 12vw;
    text-align: left;
}

.sectionbody > div > button {
    margin-left: 60%;
}

.sectionbody > div > a > button {
    margin-left: 60%;
}


.hidden {
    visibility: hidden;
}


@media (max-width: 700px) {

    .homecontainer {
        display: flex;
        flex-direction: column;
    }

    .productscontainer {
        display: flex;
        flex-direction: column;
    }

    .mobileflex {
        height: 90vh;
        width: 100vw !important;
        display: flex;
        position: relative;
        flex-direction: column;
        left: 0;
        margin: 0;
        padding: 10px;

    }

    .mainsplash {
        position: absolute;
        width: 160vw;
        left: -60vw;

    }


    .leadbox {
        height: 54vh !important;
        max-width: 100vw;
        justify-content: end;
        margin-bottom: 25vh;
    }

    .leadbox > p {
        text-align: center;
        font-size: 6vw;
        line-height: 6vw;
    }
    
    .leftheader {
        text-align: center;
        font-size: 9vw;
        font-weight: bolder;
        line-height: 11vw;
    }


    .mobileonly {
        visibility: visible;
    }

    .hideonmobile {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }

    .partnerbox {
        font-size: 5vw; 
        text-align: center;
        height: 22vh;
    }

    .partnerimages {
        width: 90vw;
        margin: 0;
    }


    .productsection {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 10vh;
    }

    .centerheader {
        text-align: center;
        font-size: 9vw;
        font-weight: bolder;
        line-height: 11vw; 
    }

    .sectionbody {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        flex-direction: column;
    }

    .sectionbody > img {
        max-width: 50vw;
        align-self: center;
    }

    .sectiontext > p {
        text-align: center !important;
        padding: 0 !important;
    }

    .sectiontext > button {
        margin-left: 25% !important;
    }


}


@media (min-width: 700px) {

    .partnerbox {
        position: absolute;
        top: 75vh;
        width: 50%;
        margin-left: 10vw;
        color: #817e7e;
        font-size: 18px;
        text-align: center;
        z-index: 5;
    }
}


.page-container {
    margin-top: 70px;
    height: 100vh;
    font-size: 18px;
    color: white;
    overflow: hidden;
}

/*
.partnersLogo{
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 100vh;
}

.partnersLogo .col{
    padding: 5px;
    margin: 5px;
}
*/

.partnersLogo img{
    border-style: solid;
    border-width: 1px;
    border-color:lightgrey;
}

.news-container{
    margin: 70px;
}

.accordion {
    background-color: rgb(248, 248, 248);
    color: rgb(223, 223, 223);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    text-align: center;
    color: rgb(232, 116, 20);
    transition: 0.4s;
  }

  .products-page {

  }


  .active, .accordion:hover {
    background-color: #ccc;
  }

  .panel {
    padding: 0 18px;
    background-color: white;
    padding-left: 45px;
    padding-right: 45px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
  }

  /*
    Rule affects all placeholder text on a stdpage
    Opacity needs to be set for Firefox browser
  */

  .form-control::-webkit-input-placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control::-moz-placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control:-ms-input-placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control::-ms-input-placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control::placeholder {
    color: rgb(0, 0, 0);
    opacity: 1; /* Firefox compat */
  }

  .form-control::-ms-input-placeholder { /* Microsoft Edge compat*/
    color: rgb(0, 0, 0);
  }





.flip-card {
    background-color: transparent;
    width: 299px;
    height: 417px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-left: 10vw;
  }

 

  .flip-card-news {
    background-color: black;
    width: 299px;
    height: 417px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-left: 10vw;
  }

  .flip-card-news:hover .flip-card-inner{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    height: 200%;
    z-index: 99;

  }

  .flip-card-news:hover ~ .flip-card-news{
    -webkit-transform: translateY(400px);
            transform: translateY(400px)
    /*got it*/
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  
  .flip-card img{
    max-height: 417px;
  }
  

  .flip-card-front, .flip-card-back {
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: transparent;
    color: black;
  }
  
  .flip-card-back {
    background-color: transparent;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }


  .hover-grow-vert {
    -webkit-transform: scale(0, 2);
            transform: scale(0, 2);

  }
.grow-card {
    border: 1px;
    margin-bottom: 10px;

}

.grow-card:hover ~ .grow-card{
    -webkit-transform: translateY(20px);
            transform: translateY(20px)
    /*got it*/
  }

  .grow-card:hover .introContent{
    visibility: hidden;
  }

  .grow-card:hover .fullContent{
      visibility: visible;
      height: 100%;
      width: 70vw;
  }

.introContent {
    background-color: orange;
}

.fullContent {
    visibility:hidden;
   background-color: green;
    height: 0px;
    width: 0px;
}
.card-wrapper {
    display: inline-block;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: transparent;
  }
  .card-wrapper .card {
    position: relative;
    cursor: pointer;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
 
  }
  .card-wrapper .card .front,
  .card-wrapper .card .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);

  }
  .card-wrapper .card .front {
    z-index: 1;
  }
  .card-wrapper .card .back,
  .card-wrapper.flip-right .card .back {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }
  .card-wrapper:hover .card,
  .card-wrapper.flip-right:hover .card {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    
  }

  /*
  .card-wrapper:hover .card .front {
 
  }


  .card-wrapper.flip-left .card .back {
    transform: rotateY(-180deg);
  }
  .card-wrapper.flip-left:hover .card {
    transform: rotateY(-180deg);
  }
  .card-wrapper.flip-up .card .back {
    transform: rotateX(180deg);
  }
  .card-wrapper.flip-up:hover .card {
    transform: rotateX(180deg);
  }
  .card-wrapper.flip-down .card .back {
    transform: rotateX(-180deg);
  }
  .card-wrapper.flip-down:hover .card {
    transform: rotateX(-180deg);
  }
  .card-wrapper.flip-diagonal-right .card .back {
    transform: rotate3d(1, 1, 0, 180deg);
  }
  .card-wrapper.flip-diagonal-right:hover .card {
    transform: rotate3d(1, 1, 0, 180deg);
  }
  .card-wrapper.flip-diagonal-left .card .back {
    transform: rotate3d(1, 1, 0, -180deg);
  }
  .card-wrapper.flip-diagonal-left:hover .card {
    transform: rotate3d(1, 1, 0, -180deg);
  }
  .card-wrapper.flip-inverted-diagonal-right .card .back {
    transform: rotate3d(-1, 1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-right:hover .card {
    transform: rotate3d(-1, 1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-left .card .back {
    transform: rotate3d(1, -1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-left:hover .card {
    transform: rotate3d(1, -1, 0, 180deg);
  }
  */





 .card .front img {
      max-height: 417px;
  }

  .card .back img{
    max-height: 417px;
  }

  

  .card-wrapper,
  .card {
    width: 299px;
    height: 417px;
    margin: 0px;
  }
  .card .front,
  .card .back {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #86dffae3;
    border-radius: 4px;
    overflow: hidden;
  }
  .card .front {
    color: #97d5ff;
    background: #ffffff;
    font-weight: 700;
    font-size: 1rem;
  }
  .card .back {
    font-size: 1.5rem;
    color: #ffffff;
    background: #0698ec;
  }
  
