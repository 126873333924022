.card-wrapper {
    display: inline-block;
    perspective: 1000px;
    background-color: transparent;
  }
  .card-wrapper .card {
    position: relative;
    cursor: pointer;
    transition: all 1s;
    transition-timing-function: ease-in-out;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
 
  }
  .card-wrapper .card .front,
  .card-wrapper .card .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);

  }
  .card-wrapper .card .front {
    z-index: 1;
  }
  .card-wrapper .card .back,
  .card-wrapper.flip-right .card .back {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }
  .card-wrapper:hover .card,
  .card-wrapper.flip-right:hover .card {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    
  }

  /*
  .card-wrapper:hover .card .front {
 
  }


  .card-wrapper.flip-left .card .back {
    transform: rotateY(-180deg);
  }
  .card-wrapper.flip-left:hover .card {
    transform: rotateY(-180deg);
  }
  .card-wrapper.flip-up .card .back {
    transform: rotateX(180deg);
  }
  .card-wrapper.flip-up:hover .card {
    transform: rotateX(180deg);
  }
  .card-wrapper.flip-down .card .back {
    transform: rotateX(-180deg);
  }
  .card-wrapper.flip-down:hover .card {
    transform: rotateX(-180deg);
  }
  .card-wrapper.flip-diagonal-right .card .back {
    transform: rotate3d(1, 1, 0, 180deg);
  }
  .card-wrapper.flip-diagonal-right:hover .card {
    transform: rotate3d(1, 1, 0, 180deg);
  }
  .card-wrapper.flip-diagonal-left .card .back {
    transform: rotate3d(1, 1, 0, -180deg);
  }
  .card-wrapper.flip-diagonal-left:hover .card {
    transform: rotate3d(1, 1, 0, -180deg);
  }
  .card-wrapper.flip-inverted-diagonal-right .card .back {
    transform: rotate3d(-1, 1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-right:hover .card {
    transform: rotate3d(-1, 1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-left .card .back {
    transform: rotate3d(1, -1, 0, 180deg);
  }
  .card-wrapper.flip-inverted-diagonal-left:hover .card {
    transform: rotate3d(1, -1, 0, 180deg);
  }
  */





 .card .front img {
      max-height: 417px;
  }

  .card .back img{
    max-height: 417px;
  }

  

  .card-wrapper,
  .card {
    width: 299px;
    height: 417px;
    margin: 0px;
  }
  .card .front,
  .card .back {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #86dffae3;
    border-radius: 4px;
    overflow: hidden;
  }
  .card .front {
    color: #97d5ff;
    background: #ffffff;
    font-weight: 700;
    font-size: 1rem;
  }
  .card .back {
    font-size: 1.5rem;
    color: #ffffff;
    background: #0698ec;
  }
  