.grow-card {
    border: 1px;
    margin-bottom: 10px;

}

.grow-card:hover ~ .grow-card{
    transform: translateY(20px)
    /*got it*/
  }

  .grow-card:hover .introContent{
    visibility: hidden;
  }

  .grow-card:hover .fullContent{
      visibility: visible;
      height: 100%;
      width: 70vw;
  }

.introContent {
    background-color: orange;
}

.fullContent {
    visibility:hidden;
   background-color: green;
    height: 0px;
    width: 0px;
}