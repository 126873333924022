
.productscontainer {
    margin-top: 10vh;
}

.mainsplash {
    /*padding-top: 70px;*/
    position: relative;
    height: 100vh;
    /* margin-left: 6vw;
    margin-right: 6vw; */
    background-color: black;
    z-index: -2;
    /* position: relative; */
}

.gb-logo{
    /* padding-left: 0 !important; */
    animation: fade-in-out-in 8s ease-in-out forwards;
    animation-delay: 4s;
    min-width: inherit;
}

.splash-columns{
    width: 30vw;
    padding-left: 7vw;
}


.gb-nav{
    opacity: 0;
    animation: fade-in 4s 2s forwards;
}

.base-image {
    /* height: calc(100vh - 70px); */
    position: absolute;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    
}

.fade-image {
    /* height: calc(100vh - 70px); */
    position: absolute;
    width: 100%;
    animation: fade-out 4s forwards;
    object-fit: cover; 
}

.fade-image:after {
    background: linear-gradient(
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.0) 70%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0.7) 90%,
      rgba(0, 0, 0, 0.9) 95%,
      rgba(0, 0, 0, 1.0) 100%
    );
  }

.splash-grid {
    height: inherit;
    margin-left: 0;
    padding-left: 0;
}

.splash-grid .row {
    height: 33vh;
    width: 100vw;
    align-items: center;
    flex-wrap: nowrap;
}

.splash-grid .col {
    float: right;
}

#supplier-art {
    /* padding-left: 40vw; */
    min-height: 40vh;
    float: right;
    padding-right: 10vw;
}

.splash-grid img {
    height: 19vh;
    opacity: 0;
    padding: 20px;
    /* animation: fade-in 4s 6s forwards;     */
}

.first-fade{
    animation: fade-in 6s forwards;
}

.second-fade{
    animation: fade-in 7s forwards;
    animation-delay: 7s;
}

.third-fade{
    animation: fade-in 8s forwards;
}

.fourth-fade-fade{
    animation: fade-in 8s forwards;
}

.fifth-fade{
    animation: fade-in 8s forwards;
    animation-delay: 9s;
}

.logo-move{
    animation: fade-in 6s forwards;
}

@keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fade-in{
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fade-in-out-in{
    20% { opacity: 1; }
    85% {opacity: 0;}
    100% {opacity: 1}
}

@media (max-width: 800px){
    .hide-on-small{
        visibility: hidden;
    }
}


@media (max-width: 800px){
    .shrink-on-small{
        max-height: 175px;
    }
}

/* Revised homepage styles */
.leadbox {
    color: white;
    position: absolute;
    top: 18vh;
    left: 18vw;
    max-width: 30vw;
    /*border: 2px solid white;*/

}

.leadbox > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 20px;

}

.leftheader {
    font-size: 56px;
    font-weight: bolder;
    line-height: 65px;
}



.partnerbox > p {
    font-size: 16px;
    color: #817e7e;
}

.partnerimages {
    display: flex;
    flex-direction: row;
    width: 65%;
    margin-left: 9vw;
}

.marqueeimage {
    width: 100px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 2px;
    opacity: .6;
} 


.hashnavdummy {
    height: 70px;
}


.productsection {
    color: white;
    margin-bottom: 25vh;
}

.sectionbody {
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    margin-right: 10vw;
    justify-content: space-between;
}

.centerheader {
    font-size: 46px;
    line-height: 65px;
    text-align: center;
}

.productsection > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 15px;
    text-align: center;
}

.sectionbody > img {
    max-height: 500px;
}

.sectionbody > div > p {
    font-size: 22px;
    color: #817e7e;
    padding-top: 9vh;
    padding-left: 5vw;
    padding-right: 12vw;
    text-align: left;
}

.sectionbody > div > button {
    margin-left: 60%;
}

.sectionbody > div > a > button {
    margin-left: 60%;
}


.hidden {
    visibility: hidden;
}


@media (max-width: 700px) {

    .homecontainer {
        display: flex;
        flex-direction: column;
    }

    .productscontainer {
        display: flex;
        flex-direction: column;
    }

    .mobileflex {
        height: 90vh;
        width: 100vw !important;
        display: flex;
        position: relative;
        flex-direction: column;
        left: 0;
        margin: 0;
        padding: 10px;

    }

    .mainsplash {
        position: absolute;
        width: 160vw;
        left: -60vw;

    }


    .leadbox {
        height: 54vh !important;
        max-width: 100vw;
        justify-content: end;
        margin-bottom: 25vh;
    }

    .leadbox > p {
        text-align: center;
        font-size: 6vw;
        line-height: 6vw;
    }
    
    .leftheader {
        text-align: center;
        font-size: 9vw;
        font-weight: bolder;
        line-height: 11vw;
    }


    .mobileonly {
        visibility: visible;
    }

    .hideonmobile {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }

    .partnerbox {
        font-size: 5vw; 
        text-align: center;
        height: 22vh;
    }

    .partnerimages {
        width: 90vw;
        margin: 0;
    }


    .productsection {
        height: fit-content;
        margin-bottom: 10vh;
    }

    .centerheader {
        text-align: center;
        font-size: 9vw;
        font-weight: bolder;
        line-height: 11vw; 
    }

    .sectionbody {
        height: fit-content;
        flex-direction: column;
    }

    .sectionbody > img {
        max-width: 50vw;
        align-self: center;
    }

    .sectiontext > p {
        text-align: center !important;
        padding: 0 !important;
    }

    .sectiontext > button {
        margin-left: 25% !important;
    }


}


@media (min-width: 700px) {

    .partnerbox {
        position: absolute;
        top: 75vh;
        width: 50%;
        margin-left: 10vw;
        color: #817e7e;
        font-size: 18px;
        text-align: center;
        z-index: 5;
    }
}

